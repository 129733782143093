import { proxy } from "valtio";
import { devtools } from "valtio/utils";
const widState = proxy({
  bread: [{ name: "My Folder", id: "0fx6ef888f9bfcdb040bd9084653db3c65a8c" }],
  view: "list",
  apiData: {},
  loading: true,
  id: "",
  pasteOpen: false,
  settingData: {},
  orgId: "",
  fieldApiName: "",
  apiKey: "",
  apiDomain: "",
  snackbar: {
    open: false,
    severity: "success",
    message: "This is a success message",
  },
  setFieldApiName: (fieldApiName) => {
    widState.fieldApiName = fieldApiName;
  },
  setSnackbar: (data) => {
    widState.snackbar = {
      open: data?.bool,
      severity: data?.type,
      message: data?.message,
    };
  },
  isError: false,
  setIsError: (bool) => {
    widState.isError = bool;
  },
  errorMessage: "",
  setErrorMessage: (errorMessage) => {
    widState.errorMessage = errorMessage;
  },
  setSettingData: (settingId, key, value) => {
    let tempData = widState.settingData?.[settingId] || {};
    tempData[key] = value;
    widState.settingData[settingId] = tempData;
  },
  setRootBread: (id) => {
    widState.bread = [{ name: "Base Directory", id: id }];
  },
  setBreadCrumbs: (folder) => {
    widState.bread = [].concat(widState.bread, [
      { name: folder?.name, id: folder?.id },
    ]);
  },
  
  setRenamedItem: (settingId, folder, apiData) => {
    const folderId = folder?.id ? folder?.id : folder;
    widState.settingData[settingId].previousData[folderId] = [...apiData];
  },
  setBreadCrumbsUrl: (folder) => {
    let my_array = [];
    for (let index = 0; index < widState.bread.length; index++) {
      const element = widState.bread[index];
      if (element.id !== folder.id) my_array.push(element);
      else {
        my_array.push(element);
        break;
      }
    }
    widState.bread = my_array;
  },
  setView: (view) => {
    widState.view = view;
  },

  setApiData: (folderId, data) => {
    widState.apiData[folderId] = data;
  },

  setLoading: (bool) => {
    widState.loading = bool;
  },

  setId: (id) => {
    widState.id = id;
  },
  setPasteOpen: (bool) => {
    widState.pasteOpen = bool;
  },
  setInitializeData: (settings) => {
    let tempData = {};
    settings.forEach((setting) => {
      tempData[setting?.settingId] = {
        ...widState?.settingData?.[setting?.settingId],
        ...{
          rootFolderId: setting?.rootFolderId,
          name: setting?.Name,
          breadCrumbs: [],
          previousData: {},
          view: "list",
          thumbnailUrls: {},
          downloadUrls: {},
        },
      };
    });
    widState.settingData = tempData;
  },
  conn_name: "easyonedriveforcrm",

  setApiSettingData: (settingId, folder, apiData) => {
    let folderId = folder?.id ? folder?.id : folder;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        previousData: {
          ...widState.settingData?.[settingId]?.previousData,
          [folderId]: [...apiData],
        },
        breadCrumbs: [
          ...(widState.settingData?.[settingId]?.breadCrumbs || []),
          { name: folder?.name ? folder.name : "My Folder", id: folderId },
        ],
      },
    };
    // ;
    widState.settingData = tempData;
  },
  setRootFolderSettingData: (settingId, folder, apiData) => {
    let folderId = folder?.id ? folder?.id : folder;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        previousData: {
          [folderId]: [...apiData],
        },
        breadCrumbs: [
          { name: folder?.name ? folder.name : "My Folder", id: folderId },
        ],
        downloadUrls: {},
      },
    };
    // ;
    widState.settingData = tempData;
  },
  // setApiSettingData: (settingId, folder, apiData) => {
  //   const folderId = folder?.id ? folder?.id : folder;
  //   // ;
  //   // widState.settingData[settingId].previousData[folderId] = [
  //   //   ...(widState.settingData?.[settingId]?.previousData?.[folderId] || []),
  //   //   ...apiData,
  //   // ];
  //   // widState.settingData[settingId].breadCrumbs = [
  //   //   ...(widState.settingData[settingId].breadCrumbs || []),
  //   //   {
  //   //     name: folder?.name || "My Folder",
  //   //     id: folderId,
  //   //   },
  //   // ];
  // },
  setLoadedFolderBreadCrumbs: (settingId, folder, apiData) => {
    const folderId = folder?.id ? folder?.id : folder;
    widState.settingData[settingId].previousData[folderId] = [...apiData];
    widState.settingData[settingId].breadCrumbs = [
      ...(widState.settingData[settingId].breadCrumbs || []),
      {
        name: folder?.name || "My Folder",
        id: folderId,
      },
    ];
  },
  setBreadCrumbsSettingData: (settingId, folder) => {
    let my_array = [];
    for (
      let i = 0;
      i < widState.settingData?.[settingId].breadCrumbs.length - 1;
      i++
    ) {
      const element = widState.settingData?.[settingId].breadCrumbs?.[i];
      if (element.id !== folder.id) my_array.push(element);
      else {
        my_array.push(element);
        break;
      }
    }
    widState.settingData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        breadCrumbs: my_array,
      },
    };
  },
  setViewSettingData: (settingId, view) => {
    widState.settingData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        view: view,
      },
    };
  },
  setAddItemSettingData: (settingId, folder, apiData) => {
    let folderId = folder?.id ?? folder;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        previousData: {
          ...widState.settingData?.[settingId]?.previousData,
          [folderId]: [...apiData],
        },
      },
    };
    // ;
    widState.settingData = tempData;
    // widState.settingData[settingId].previousData[folderId] = apiData;
  },
  setRefreshData: (settingId, folder, apiData) => {
    let folderId = folder?.id ?? folder;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        previousData: {
          ...widState.settingData?.[settingId]?.previousData,
          [folderId]: [...apiData],
        },
      },
    };
    // ;
    widState.settingData = tempData;
    // widState.settingData[settingId].previousData[folderId] = apiData;
  },
  setResetData: (settingId, folder) => {
    let folderId = folder?.id ?? folder;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        thumbnailUrls: {},
        previousData: {
          ...widState.settingData?.[settingId]?.previousData,
          [folderId]: [],
        },
      },
    };
    // ;
    widState.settingData = tempData;
    // widState.settingData[settingId].previousData[folderId] = apiData;
  },
  setThumbNail: (settingId, file, thumbnail_url) => {
    let folderId = file?.id ?? file;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        thumbnailUrls: {
          ...widState.settingData?.[settingId]?.thumbnailUrls,
          [folderId]: thumbnail_url,
        },
      },
    };
    widState.settingData = tempData;
    // widState.settingData[settingId].thumbnailUrls[file?.id] = thumbnail_url;
  },
  setDeleteThumbNail: (settingId, files) => {
    let tempData = widState.settingData;
    files.forEach((file) => {
      let folderId = file?.id ?? file;
      delete tempData?.[settingId]?.thumbnailUrls?.[folderId];
    });
    widState.settingData = tempData;
    // widState.settingData[settingId].thumbnailUrls[file?.id] = thumbnail_url;
  },
  setDownloadedImage: (settingId, file, download_url) => {
    let folderId = file?.id ?? file;
    let tempData = {
      ...widState.settingData,
      [settingId]: {
        ...widState.settingData?.[settingId],
        downloadUrls: {
          ...widState.settingData?.[settingId]?.downloadUrls,
          [folderId]: download_url,
        },
      },
    };
    widState.settingData = tempData;
    // widState.settingData[settingId].downloadUrls[file?.id] = download_url;
  },
});
const unsub = devtools(widState, "Drive Manager State");
export { widState };
